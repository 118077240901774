section.wat-section.seccion-checkout {
    .seccion-checkout--content {
        .info-fija {
        }
        .checkout-pago {
        }
        .entradas-compradas {
            .entrada-comprada {
                flex-basis: calc((100% / 2) - (24px * 1 / 2));
            }
        }
    }
}

.cola-virtual{
    .cola-section{
        flex-basis: calc(610px);
    }
    
    .info-section {
        flex-basis: calc(100% - 610px - 30px);
    }
}