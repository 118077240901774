form {
    &.back-white {
        .form--content {
            label {
                color: $grey;
                font-weight: 300;
                span {
                    color: $grey;
                    font-weight: 300;
                }
            }
            .input--content {
                .input {
                    input {
                        color: #777;
                        border-color: #E6E6E6;
                        background-color: #FAFAFA;
                        box-shadow: 0px 0px 0px 51px #FAFAFA;
                    }
                }
            }
        }
    }
    .form--content {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        .input--content {
            flex-basis: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;

            .spam {
                label {
                    font-size: 14px;
                    line-height: 21px;
                    color: $grey;
                    position: relative;
                    font-weight: 400;
                    b {
                        font-size: 14px;
                        line-height: 21px;
                        color: $grey;
                        font-weight: 600;
                    }
                    &::before {
                        content: "";
                        display: inline-block;
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                        border: 1px solid $grey;
                        margin-right: 8px;
                        transform: translateY(2px);
                    }
                    &::after {
                        content: "";
                        position: absolute;
                        width: 6px;
                        height: 6px;
                        display: block;
                        border-radius: 50%;
                        background-color: $grey;
                        top: 5px;
                        left: 4px;
                        opacity: 0;
                        visibility: hidden;
                        transition: .3s ease;
                    }
                }
                input {
                    position: absolute;
                    &:checked + label {
                        &::after {
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
            }
            
            .input {
                width: 100%;
                position: relative;
                .icon-password {
                    cursor: pointer;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 0;
                    width: 64px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                    .icon {
                        width: 20px;
                    }
                }
                input {
                    padding: 8px 64px 8px 12px;
                    width: calc(100% - 76px - 2px);
                    border-radius: 8px;
                    border: 1px solid $bg-input;
                    background-color: $bg-input;
                    font-size: 14px;
                    line-height: 22px;
                    color: $white;
                    &[readonly] {
                        background-color: $bg-input;
                        border-color: #52525B;
                        opacity: .5;
                    }
                }

                mat-datepicker-toggle {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: calc(100% - 28px);
                    padding-left: 14px;
                    padding-right: 14px;
                    bottom: 0;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    .mat-mdc-icon-button {
                        width: 30px;
                        height: 30px;
                        padding: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        svg {
                            width: 20px;
                            fill: $white;
                        }
                        .mat-mdc-button-touch-target {
                            width: 20px;
                            height: 20px;
                        }
                    }
                }

                mat-select {
                    padding: 8px 0 8px 0;
                    width: 100%;
                    border-radius: 8px;
                    border: 1px solid $bg-input;
                    background-color: $bg-input;
                    border: none;

                    font-size: 14px;
                    line-height: 22px;
                    color: $white;
                    .mat-mdc-select-trigger {
                        padding: 0 12px;
                    }
                    .mat-mdc-select-arrow-wrapper {
                        width: 30px;
                        height: 22px;
                        .mat-mdc-select-arrow {
                            width: 24px;
                            svg {
                                fill: $white;
                            }
                        }
                    }
                }
            }

            .remember-password {
                margin-left: auto;
                a {
                    font-size: 12px;
                    line-height: 16px;
                    color: #777;
                    font-weight: 700;
                    text-align: right;
                }
            }
        }
        .politicas--content {
            flex-basis: 100%;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            input {
                position: absolute;
                &:checked + label {
                    .icon {
                        &::after {
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
            }
            label {
                font-size: 14px;
                line-height: 22px;
                font-weight: 400;
                color: $grey;
                .icon {
                    display: inline-block;
                    width: 13px;
                    height: 13px;
                    margin-right: 6px;
                    transform: translateY(1px);
                    transition: .3s ease;
                    position: relative;
                    &::before {
                        content: "";
                        display: block;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                        border: 1px solid $grey;
                        margin-right: 8px;
                        // transform: translateY(2px);
                    }
                    &::after {
                        content: "";
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 6px;
                        height: 6px;
                        display: block;
                        border-radius: 50%;
                        background-color: $grey;
                        opacity: 0;
                        visibility: hidden;
                        transition: .3s ease;
                    }
                }
                a {
                    text-decoration: none;
                    color: $primary;
                }
            }
            a.btn-line {
                color: $primary;
                text-decoration: none;
                font-size: 14px;
                line-height: 22px;
            }
        }
        .submit--content--popup {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 24px;
            .btn-primary {
                width: 100%;
            }
            .separator {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                max-width: 180px;
                gap: 12px;
                p {
                    font-size: 16px;
                    line-height: 20px;
                    color: #A8A8A8;
                }
                span {
                    flex: 1;
                    height: 1px;
                    background-color: #A8A8A8;
                }
            }
            .btn-google {
                padding: 6px 14px;
                text-decoration: none;
                width: calc(100% - 28px);
                max-width: calc(240px - 28px);
                background-color: #F1F1F1;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 8px;
                p {
                    font-size: 16px;
                    line-height: 24px;
                    color: $black;
                }
            }
            .politicas {
                p {
                    color: #757575;
                    font-size: 12px;
                    line-height: 16px;
                    text-align: center;
                    a {
                        color: #757575;
                        font-size: 12px;
                        line-height: 16px;
                        text-decoration: underline;
                        text-align: center;
                    }
                }
            }
            .buttons {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 12px;
            }
        }
        .submit--content {
            flex-basis: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            a.btn-reset {
                margin-right: auto;
                color: $primary;
                position: relative;
                font-size: 14px;
                line-height: 24px;
                &::after {
                    content: "";
                    position: absolute;
                    left: 0;
                    right: 0;
                    height: 1px;
                    background-color: $primary;
                    bottom: 2px;
                }
            }
            a.btn-secondary {
                margin-right: 8px;
                margin-left: auto;
            }
            button {
                // margin-left: auto;
            }
        }

        .phone--content {
            flex-basis: 100%;
            position: relative;
            z-index: 900;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            .input {
                width: 100%;
                position: relative;
                ngx-intl-tel-input {
                    &.ng-invalid {
                        input.custom {
                            border-color: $primary;
                        }
                    }
                    .iti {
                        width: 100%;
                        .country-dropdown {
                            border: none !important;
                            width: 86px;
                            .iti__country-list {
                                position: absolute;
                                z-index: 1000000;
                                list-style: none;
                                text-align: left;
                                padding: 0;
                                margin: 0 0 0 -1px;
                                box-shadow: 1px 1px 4px #0003;
                                background-color: #fff;
                                border: 1px solid #CCC;
                                white-space: nowrap;
                                max-height: calc(35px * 4);
                                width: 86px;
                                overflow-y: scroll;
                                -webkit-overflow-scrolling: touch;
                            }
                        }
                        .iti__flag-container {
                            width: 48px;
                            height: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            left: 0;
                            .iti__selected-flag {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                .iti__arrow {
                                    border-top-color: #555;
                                }
                            }
                        }
                        // input {
                        //     padding: 22px 60px 6px 100px;
                        //     background-color: $white;
                        //     border: 1px solid $white;
                        //     font-size: 14px;
                        //     line-height: 20px;
                        //     font-weight: 300;
                        //     border-radius: 5px;
                        //     color: $black;
                        //     width: 100%;
                        //     transition: 0.3s ease;
                        //     caret-color: $black;
                        //     background-color: $background;
                        //     &:-webkit-autofill,
                        //     &:-webkit-autofill:hover, 
                        //     &:-webkit-autofill:focus, 
                        //     &:-webkit-autofill:active{
                        //         -webkit-box-shadow: 0 0 0 30px $white inset !important;
                        //     }
                        //     &:-webkit-autofill{
                        //         -webkit-text-fill-color: $black !important;
                        //     }
                        // }
                        .dropdown-menu {
                            min-width: initial;
                            width: 86px;
                            border-radius: 0 !important;
                            input {
                                padding: 10px;
                                font-size: 10px;
                                line-height: 14px;
                            }
                            ul {
                                li {
                                    padding: 10px;
                                    display: flex;
                                    align-items: center;
                                    &.iti__divider {
                                        padding: 0;
                                    }
                                    .iti__country-name {
                                        display: none;
                                    }
                                    span {
                                        font-size: 10px;
                                        line-height: 14px;
                                    }
                                }
                            }
                        }
                    }
                    input.custom {
                        padding: 8px 8px 8px 52px;
                        width: 100%;
                        border-radius: 8px;
                        border: 1px solid $bg-input;
                        background-color: $bg-input;
                        font-size: 14px;
                        line-height: 22px;
                        color: $white;
                    }
                }
            }
        }

        label {
            font-size: 16px;
            line-height: 24px;
            font-weight: 600;
            span {
                color: $primary;
            }
        }
        .information {
            color: $grey;
            font-family: "Roboto", sans-serif;
            font-size: 12px;
            line-height: 18px;
            margin-top: 4px;
            .icon {
                transform: translateY(1px);
                display: inline-block;
                width: 12px;
            }
        }
    }
}

form.formulario-busqueda {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2000;
    background: rgba(8, 42, 69, 0.60);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    transition: .3s ease;
    padding: 90px 5vw;
    &.active {
        opacity: 1;
        visibility: visible;
    }
    .fondo {
        position: absolute;
        z-index: 5;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    .formulario-busqueda--content {
        position: relative;
        z-index: 10;
        width: 90vw;
        // height: calc(100svh - 180px);
        .search--content {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 16px;
            .input {
                position: relative;
                .icon-search {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 16px;
                    left: 18px;
                }
                input {
                    padding: 12px 52px 12px 52px;
                    width: calc(100% - 104px);
                    border-radius: 20px;
                    border: 1px solid $white;
                    background-color: $white;
                    border: none;

                    font-size: 16px;
                    line-height: 22px;
                    color: $background;
                }
                .icon-cross {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 16px;
                    right: 18px;
                }
            }
            .results {
                max-height: calc(100svh - 270px);
                padding: 14px 18px;
                background-color: $white;
                border-radius: 10px;
                display: flex;
                flex-direction: column;
                gap: 10px;
                .results-title {
                    p {
                        font-size: 12px;
                        line-height: 20px;
                        color: $background;
                        font-weight: 700;
                    }
                }
                .results-results {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 16px;
                    max-height: calc(100% - 20px - 10px);
                    overflow: auto;
                    .result {
                        flex-basis: 100%;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

form.formulario-codigo-evento {
    .formulario-codigo-evento--content {
        display: flex;
        align-items: center;
        gap: 8px;
        .input--content {
            flex: 1;
            input {
                text-align: center;
                background-color: $background-bold;
                border: none;
                padding: 14px 10px;
                border-radius: 8px;
                width: calc(100% - 20px);
                font-size: 12px;
                line-height: 16px;
                font-weight: 600;
                &::placeholder {
                    font-weight: 400;
                }
            }
        }
        .submit--content {
            flex-basis: 56px;
            min-width: 56px;
            button {
                display: flex;
                align-items: center;
                justify-content: center;
                border: none;
                background-color: $primary;
                padding: 0;
                height: 43px;
                border-radius: 8px;
                width: 100%;
                color: $white;
                &[disabled] {
                    opacity: .5;
                }
                .icon {
                    width: 16px;
                }
            }
        }
    }
}

.cdk-overlay-pane {
    border-radius: 10px !important;
    overflow: hidden;
    .mat-datepicker-content {
        border: 10px !important;
    }
    .mat-mdc-select-panel {
    }
    h1, h2, h3, h4, p, input, label, span, a, textarea {
        color: $black;
        &.mat-calendar-body-selected {
            color: $white;
        }
    }
}

.expandable-overlay-panel {
    width: 90vw !important; /* Adjust as needed */
    max-width: 340px;
    left: 50% !important;
    transform: translateX(-50%);
}

.cdk-overlay-container {
    z-index: 1400;
    background-color: #182530b2;
    .cdk-overlay-backdrop {
    }
    .mat-mdc-option {
        font-family: "Roboto", sans-serif !important;
        .mdc-list-item__primary-text {
    
        }
    }
}

@media screen and (min-width: 768px) {
    form.formulario-busqueda {
        padding: 160px 5vw;
        .formulario-busqueda--content {
            max-width: 730px;
            margin: 0 auto;
        }
    }
    form.formulario-codigo-evento {
        .formulario-codigo-evento--content {
            .input--content {
                min-width: 144px;
                input {
                    padding: 15px 10px;
                }
            }
            .submit--content {
                button {
                    height: 46px;
                }
            }
        }
    }
    form {
        .form--content {
            gap: 20px 10px;
            .input--content {
                &.input--content--middle {
                    flex-basis: calc((100% / 2) - (10px * 1 / 2));
                }
            }
        }
    }
}

@media screen and (min-width: 1200px) {
    form {
        .form--content {
            .input--content, .phone--content {
                &.input--content--third {
                    flex-basis: calc((100% / 3) - (10px * 2 / 3));
                }
            }
        }
    }
}