.btn-icon {
    width: 100%;
    padding: 6px 14px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    transition: .3s ease;
    border: none;
    outline: none;
    &.disabled, &:disabled {
        opacity: .4;
        cursor: not-allowed;
    }
    &::after {
        content: "";
        display: block;
        min-width: 16px;
        width: 16px;
        height: 16px;
    }
    .icon {
        width: 16px;
        min-width: 16px;
    }
    p {
        color: $white;
        font-size: 16px;
        line-height: 24px;
    }
}

a.btn-icon {
    width: calc(100% - 28px);
}

.btn-icon-two {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    border-radius: 10px;
    background-color: #27272A;
    border-color: #27272A;
    .icon {
        width: 20px;
        min-width: 20px;
    }
    p {
        font-size: 14px;
        line-height: 22px;
        color: $primary;
    }
}

.btn-primary {
    border: none;
    padding: 6px 14px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    transition: .3s ease;
    min-width: 94px;
    background-color: $bg-red-light;
    &.disabled, &:disabled {
        opacity: .4;
        cursor: not-allowed;
    }
    p {
        color: $white;
        font-size: 16px;
        line-height: 24px;
    }
}

.btn-secondary {
    border: none;
    padding: 6px 14px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    transition: .3s ease;
    background-color: $white;
    &.disabled, &:disabled {
        opacity: .4;
        cursor: not-allowed;
    }
    p {
        color: $black;
        font-size: 16px;
        line-height: 24px;
    }
}

.bg-red {
    background-color: $bg-red;
}
.bg-black {
    background-color: $bg-black;
}
.bg-red-light {
    background-color: $bg-red-light;
}

.btn-white {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 24px;
    border-radius: 25px;
    border: 2px solid $white;
    background-color: $white;
    p {
        font-weight: 400;
        color: $black;
        font-size: 14px;
        line-height: 22px;
    }
}