header.header-admin {
    .header-admin--content {
        padding: 40px 0;
    }
}

header.header {
    .header--content {
        padding: 40px 0;
    }
}

footer.footer {
    .footer--content {
        padding: 16px 30px;
        max-width: 1660px;
        margin: 0 auto;
    }
}

.wat-mainrow-admin, .wat-mainrow-public {
    &.wat-mainrow-single-evento {
        gap: 160px;
    }
}

section.wat-section.seccion-mi-cuenta {
    .seccion-mi-cuenta--content {
        padding: 0;
        .informacion {
            flex-basis: 340px;
            .avatar {
            }
            .informacion-texto {
                p.name {
                }
                p.email {
                }
            }
            .buttons {
            }
        }
        .form {
            flex-basis: calc(100% - 422px);
        }
    }
}

section.wat-section.seccion-grid-eventos {
    .seccion-grid-eventos--content {
        padding: 0;
        gap: 80px;
        .grid-eventos--title {
            max-width: 780px;
        }
        .grid-eventos {
            .grid-eventos-title {
                margin-bottom: 20px;
            }
            .grid-eventos-eventos-publicos {
                gap: 32px 24px;
            }
        }
    }
}

section.wat-section.seccion-publicar-evento {
    .seccion-publicar-evento--content {
        padding: 0;
    }
}


section.wat-section.seccion-evento-ind {
    .seccion-evento-ind--content {
        padding: 0;
        .informacion-evento {
            gap: 48px;
            .entradas-packs {
                .entradas-packs--content {
                    .title {
                        margin-bottom: 20px;
                    }
                }
            }
            .informacion-general {
                .informacion-general--content {
                    .title {
                        margin-bottom: 20px;
                    }
                }
            }
            .informacion-recinto {
                .informacion-recinto--content {
                    .title {
                        margin-bottom: 20px;
                    }
                    .info-recinto {
                        gap: 40px;
                        .info {
                            flex-basis: calc(100% - 128px - 40px);
                            gap: 20px;
                        }
                    }
                }
            }
        }
    }
}

section.wat-section.seccion-checkout {
    .seccion-checkout--content {
        padding: 0 60px;
        .info-fija {
            max-width: 760px;
            .titulo {
                p {
                    font-size: 26px;
                    line-height: 34px;
                }
            }
        }
    }
    .seccion-checkout--content--last{
        .info-fija{
            max-width: initial;
        }
    }
}


.cola-virtual {
    padding: 30px 0;
    .cola-section{
    }
    
    .info-section {
    }
}