.image--content {
    position: relative;
    overflow: hidden;
    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        object-fit: cover;
        width: 100%;
        height: 100%;
        display: block;
    }
}
img{
    image-rendering: auto;
    -webkit-image-rendering: auto;
    -moz-image-rendering: auto;
    -ms-interpolation-mode: bicubic;   
}
.evento {
    position: relative;
    &.evento-pasado {
        &::after {
            content: "";
            position: absolute;
            z-index: 100;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: $background;
            opacity: .5;
            border-radius: 10px;
        }
    }
    .evento--content {
        aspect-ratio: 1;
        width: 100%;
        position: relative;
        display: flex;
        align-items: flex-end;
        overflow: hidden;
        border-radius: 10px;
        &::after, &::before {
            content: "";
            position: absolute;
            z-index: 100;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background-color: $background;
            display: block;
            left: 50%;
            transform: translateX(-50%);
            top: -14px;
        }
        &::after {
            bottom: -14px;
            top: initial;
        }
        .image--content {
            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
        .informacion {
            width: calc(100% - 32px);
            padding: 16px;
            position: relative;
            z-index: 10;
            display: flex;
            align-items: flex-end;
            gap: 20px;
            .info-texto {
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 4px;
                &::after {
                    content: "";
                    position: absolute;
                    z-index: -1;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    top: 0;
                    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 50%, rgba(0, 0, 0, 0.40) 100%);
                }
                .titulo {
                    p {
                        font-size: 20px;
                        line-height: 24px;
                        font-weight: 700;
                        text-transform: uppercase;
                    }
                }
                &>p {
                    font-size: 16px;
                    line-height: 19px;
                }
                .info-destacada {
                    background-color: $primary;
                    padding: 4px;
                    border-radius: 2px;
                    p {
                        font-size: 10px;
                        line-height: 14px;
                        letter-spacing: 1.1px;
                        text-transform: uppercase;
                    }
                }
            }
            .ticket {
                flex-basis: 45px;
                min-width: 45px;
                position: relative;
                aspect-ratio: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                .image {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    overflow: hidden;
                    img {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                        display: block;
                    }
                }
                p {
                    position: relative;
                    z-index: 10;
                    font-size: 18px;
                    line-height: 21px;
                    text-align: center;
                    font-weight: 700;
                }
            }
        }
    }
}

.evento-publico {
    text-decoration: none;
    .evento-publico--content {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .image--content {
            width: 100%;
            aspect-ratio: 1;
            border-radius: 10px;
        }
        .informacion {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 2px;
            .titulo {
                margin-bottom: 2px;
                p {
                    font-size: 16px;
                    line-height: 20px;
                    font-weight: bold;
                }
            }
            p {
                font-size: 14px;
                line-height: 18px;
                &.date {
                    color: $primary;
                }
            }
        }
    }
}

.result {
    .result--content {
        display: flex;
        align-items: center;
        gap: 12px;
        .image--content {
            aspect-ratio: 1;
            flex-basis: 72px;
            min-width: 72px;
            border-radius: 10px;
        }
        .informacion {
            display: flex;
            flex-direction: column;
            gap: 2px;
            .title {
                p {
                    font-weight: 700;
                    color: $background;
                }
            }
            p {
                font-size: 12px;
                line-height: 18px;
                color: $black;
            }
        }
    }
}

.entrada, .pack {
    &.entrada-agotada, &.pack-agotada {
        opacity: .5;
        pointer-events: none;
        .entrada--content, .pack--content {
            .entrada-top, .pack-top {
                .buy-entrada, .buy-pack {
                    .link {
                        pointer-events: none;
                        background-color: #52525B;
                    }
                }
            }
        }
    }
    &.active{
        .entrada--content, .pack--content{
            border-color: $primary;
        }
    }
    .entrada--content, .pack--content {
        overflow: hidden;
        border-radius: 10px;
        background: $background-bold;
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
        border: 1px solid transparent;
        cursor: pointer;
        &:hover, &.destacada{
            border-color: $primary;
        }
        &>.entrada-img {
            display: none;
        }
        .entrada-top, .pack-top {
            display: flex;
            flex-direction: row;
            align-items: center;
            &.sin-imagen {
                .entrada-top--content {
                    flex-direction: row;
                    align-items: center;
                }
            }
            .entrada-img {
                flex-basis: 114px;
                min-width: 114px;
            }
            .entrada-top--content {
                flex: 1;
                padding: 12px 16px;
                display: flex;
                flex-direction: column;
                gap: 16px;
                justify-content: space-between;
                align-items: flex-end;
                .info-entrada, .info-pack {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    align-items: flex-start;
                    .name {
                        p {
                            //text-transform: uppercase;
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 16px;
                        }
                    }
                    .agotado {
                        background-color: $primary;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 4px 8px;
                        border-radius: 6px;
                        p {
                            font-weight: 600;
                            font-size: 8px;
                            text-transform: uppercase;
                            /* line-height: 20px;*/
                        }
                        &.agotado_temporalmente{
                            background:#9d6d68;
                        }
                        &.precio_promocional{
                            background:#87fbbe;
                            p{
                                color:#08440a;
                            }
                        }
                    }
                }
                .buy-entrada, .buy-pack {
                    display: flex;
                    gap: 8px;
                    .price {
                        border-radius: 8px;
                        background: rgba(63, 63, 70, 0.30);
                        min-width: 60px;
                        padding: 8px 5px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        p {
                            font-size: 18px;
                            line-height: 26px;
                            font-weight: 600;
                        }
                    }
                    .link {
                        border-radius: 8px;
                        background: $primary;
                        min-width: 40px;
                        padding: 8px 5px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        svg {
                            width: 16px;
                        }
                    }
                }
            }

        }
        .entrada-bottom, .pack-bottom {
            border-top: 1px solid #3F3F46;
            padding: 16px;
            p {
                font-size: 12px;
                line-height: 14px;
                font-weight: 600;
                color: #D4D4D8;
            }
        }
    }
}

.codigo {
    .codigo--content {
        padding: 12px 16px;
        border-radius: 10px;
        border: 1px dashed $white;
        .title {
            margin-bottom: 20px;
            p {
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
            }
        }
    }
}

.entrada-comprada {
    .entrada-comprada--content {
        .image {
            border-radius: 10px 10px 0 0;
            position: relative;
            overflow: hidden;
            aspect-ratio: 1/1;
            width: 100%;
            display: none;
            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                object-fit: cover;
                width: 100%;
                height: 100%;
                display: block;
            }
        }
        .informacion {
            padding: 12px;
            background-color: $background-bold;
            border-radius: 0 0 10px 10px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;
            .informacion-secundaria {
                display: flex;
                align-items: center;
                gap: 10px;
                .day {
                    p {
                        color: #D4D4D8;
                        font-size: 12px;
                        line-height: 18px;
                        letter-spacing: 2.45px;
                        text-transform: uppercase;
                        font-weight: 200;
                        b {
                            color: $white;
                            font-weight: 700;
                        }
                    }
                }
                .time {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 8px;
                    p {
                        color: #D4D4D8;
                        font-size: 12px;
                        line-height: 18px;
                        letter-spacing: 2.45px;
                        text-transform: uppercase;
                        font-weight: 200; 
                    }
                    .icon-separate {
                        flex-basis: 16px;
                        min-width: 16px;
                    }
                }
            }

            .informacion-primaria {
                display: flex;
                flex-direction: column;
                p {
                    font-size: 20px;
                    line-height: 32px;
                    font-weight: 600;
                }
            }
            .buttons-deco {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                
                .buttons {
                    display: flex;
                    align-items: center;
                    gap: 4px;
                    a {
                        padding: 4px 8px;
                        background-color: $black;
                        border-radius: 6px;
                        display: flex;
                        align-items: center;
                        gap: 2px;
                        text-decoration: none;
                        .icon {
                            width: 10px;
                        }
                        p {
                            font-size: 12px;
                            line-height: 16px;
                        }
                    }
                }
                .deco {
                    margin-left: auto;
                    padding: 4px 8px;
                    background-color: $primary;
                    border-radius: 6px;
                    p {
                        font-size: 12px;
                        line-height: 16px;
                        letter-spacing: 2.45px;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .codigo {
        .codigo--content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .title {
                margin-bottom: 0;
                p {
                }
            }
        }
    }

    .entrada, .pack {
        .entrada--content, .pack--content {
            .entrada-top, .pack-top {
                .entrada-img {
                    flex-basis: 98px;
                    min-width: 98px;
                }
                .entrada-top--content {
                    flex-direction: row;
                    align-items: center;
                }
            }
            .entrada-bottom, .pack-bottom {
                p {
                }
            }
        }
    }

    .entrada-comprada {
        .entrada-comprada--content {
            display: flex;
            .image {
                display: block;
                border-radius: 10px 0 0 10px;
                flex-basis: 184px;
                min-width: 184px;
            }
            .informacion {
                border-radius: 0 10px 10px 0;
                flex-basis: calc(100% - 184px - 40px);
                padding: 20px;
                .informacion-secundaria {
                    .day {
                        p {
                            b {
                            }
                        }
                    }
                    .time {
                        p {
                        }
                        .icon-separate {
                        }
                    }
                }
                .informacion-primaria {
                    p {
                    }
                }
                .buttons-deco {
                    .buttons {
                        a {
                            .icon {
                            }
                            p {
                            }
                        }
                    }
                    .deco {
                        p {
                        }
                    }
                }
            }
        }
    }
}