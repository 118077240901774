.wat-mainrow {
    &.page-login, &.page-register {
        min-height: calc(100svh - 240px - 56px);
    }
}

.wat-mainrow-public {
    min-height: calc(100svh - 56px - 320px);
    &.wat-mainrow-checkout {
    }
}

.wat-mainrow-admin {
    min-height: calc(100svh - 56px - 240px);
    &.page-mi-cuenta {
        min-height: calc(100svh - 56px - 320px);
    }
}
.aviso_checkout{
    background-color: $primary;
    position: fixed;
    bottom: 0;
    width: 30%;
    border-radius: 10px 0px 0px 0px;
    right: 0;
    left: auto;
    translate: none;
    z-index: 999;
    padding: 15px 20px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    a{
        background-color: $background;
        padding:10px 20px;
        border-radius: 10px;
        text-transform: uppercase;
        text-decoration: none;
        font-size: 12px;
        border-radius: 8px;
        position: relative;
        border: 1px solid #182530;
        transition: 0.3s ease;
        &:hover{
            background-color: rgb(51, 51, 51);
        }
    }
}

footer.footer {
    .footer--content {
        padding: 16px 5vw;
        ul {
            li {
                &::after {
                }
                &:nth-last-of-type(1) {
                    &::after {
                    }
                }
                a {
                    position: relative;
                    &:hover {
                        &::after {
                            width: 100%;
                        }
                    }
                    &::after {
                        content: "";
                        position: absolute;
                        left: 0;
                        bottom: 1px;
                        height: 1px;
                        background-color: $white;
                        width: 0%;
                        transition: .3s ease;
                    }
                }
            }
        }
        .copyright {
            max-width: initial;
            margin: initial;
            p {
                a {
                }
            }
        }
    }
}

section.wat-section.seccion-fondo {
    top: 80px;
    right: 0;
    left: 0;
    .seccion-fondo--content {
        margin-left: auto;
        width: 930px;
        transform: translateX(270px);
    }
}

section.wat-section.seccion-grid-eventos {
    .seccion-grid-eventos--content {
        .grid-eventos--title {
            max-width: 320px;
            margin-bottom: 40px;
        }
        .grid-eventos {
            .grid-eventos-title {
                h5 {
                    span {
                    }
                }
            }
            .grid-eventos-eventos, .grid-eventos-eventos-publicos {
                .evento {
                    flex-basis: calc((100% / 3) - (24px * 2 / 3));
                }
                .evento-publico {
                    flex-basis: calc((100% / 4) - (24px * 3 / 4));
                }
            }
        }
    }
}


section.wat-section.seccion-mi-cuenta {
    .seccion-mi-cuenta--content {
        gap: 40px;
        .informacion {
            flex-basis: 200px;
            padding-bottom: 0;
            border-bottom: 0;
            .avatar {
            }
            .informacion-texto {
                p.name {
                }
                p.email {
                }
            }
            .buttons {
                
            }
        }
        .form {
            padding-left: 40px;
            padding-top: 20px;
            border-left: 1px solid $white;
            flex-basis: calc(100% - 282px);
        }
    }
}

section.wat-section.seccion-publicar-evento {
    margin-top: 90px;
}

section.wat-section.seccion-evento-ind {
    .seccion-evento-ind--content {
        display: grid;
        align-items: flex-start;
        grid-template-columns: 328px auto;
        grid-gap: 40px;
        .image-evento {
            position: sticky;
            grid-area: 1 / 1 / 2 / 2;
            top: 60px;
        }
        .informacion-evento {
            grid-area: 1 / 2 / 2 / 3;
        }
    }
}

.btn-wallet{
    display: none !important;
    &.is_safari{
        display: flex !important;
    }
}


section.wat-section.seccion-popup-informacion {
    .seccion-popup-informacion--content {
        .informacion {
            .informacion-top {
                a.btn {
                    &:hover {
                        background-color: hsl(0deg 0% 100%/80%);
                    }
                }
                a.close {
                    &:hover {
                        background-color: hsl(0deg 0% 100%/80%);
                    }
                }
            }
        }
    }
}
.cola-virtual{
    flex-direction: row;
    justify-content: center;
    margin-top: 50px;
    gap: 30px;
    .cola-section{
        padding: 0 30px;
        flex-basis: calc(540px - 60px);
    }
    
    .info-section {
        margin-top: initial;
        text-align: left;
        padding: 20px 0;
        flex-basis: calc(100% - 540px - 30px);
    }
}
