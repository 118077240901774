.wat-mainrow {
    &.page-login, &.page-register {
        gap: 40px;
        min-height: calc(100svh - 240px - 108px);
        padding: 120px 0;
    }
}

.wat-mainrow-public {
    padding: 200px 0 120px;
    min-height: calc(100svh - 108px - 320px);
    &.wat-mainrow-checkout {
        padding-top: 200px;
    }
}

.wat-mainrow-admin {
    min-height: calc(100svh - 108px - 240px);
    padding: 120px 0;
    &.page-mi-cuenta {
        padding: 200px 0 120px;
        min-height: calc(100svh - 108px - 320px);
    }
}

header.header-admin {
    position: absolute;
    top: 0;
    .header-admin--content {
        height: initial;
        padding: 40px 5vw;
        background-color: initial;
        .logo {
            width: 160px;
        }
        .bars {
            display: none;
        }
        .header-secondary {
            position: initial;
            height: initial;
            background-color: initial;
            opacity: 1;
            visibility: visible;
            .header-secondary--content {
                padding: 0;
                height: initial;
                ul {
                    height: initial;
                    flex-direction: row;
                    align-items: center;
                    gap: 32px;
                    li {
                        a {
                            font-size: 16px;
                            line-height: 22px;
                        }
                    }
                }                
            }
        }
    }
}

header.header {
    .header--content {
        padding: 40px 5vw;
        height: initial;
        // background-color: $background-bold;
        .logo {
            width: 160px;
        }
        .icons {
        }
    }
}


footer.footer {
    .footer--content {
        gap: 20px;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        ul {
            flex-direction: row;
            gap: 10px;
            li {
                display: flex;
                align-items: center;
                &::after {
                    content: "";
                    margin-left: 10px;
                    display: block;
                    width: 2px;
                    height: 2px;
                    border-radius: 50%;
                    background-color: $white;
                }
                &:nth-last-of-type(1) {
                    &::after {
                        display: none;
                    }
                }
                a {
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }
        .copyright {
            max-width: 240px;
            margin: 0;
            p {
                text-align: right;
                font-size: 16px;
                line-height: 24px;
                a {
                    width: 64px;
                }
            }
        }
    }
}

section.wat-section.seccion-grid-eventos {
    .seccion-grid-eventos--content {
        .sin-eventos {
            max-width: 508px;
            margin: 0 auto;
            h3 {
            }
            &>p {
            }
        }
        .grid-eventos {
            .grid-eventos-title {
                h5 {
                    span {
                    }
                }
            }
            .grid-eventos-eventos, .grid-eventos-eventos-publicos {
                .evento, .evento-publico {
                    flex-basis: calc((100% / 2) - (24px * 1 / 2));
                }
            }
        }
    }
}

section.wat-section.seccion-publicar-evento {
    .seccion-publicar-evento--content {
        .contactar {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .titulo{
                p{
                    font-size: 52px;
                    line-height: 54px;
                }
            }
            a.btn-white {
                padding: 12px 76px;
            }
        }
    }
}

section.wat-section.seccion-evento-ind {
    .seccion-evento-ind--content {
        .image-evento {
        }
        .informacion-evento {
            .informacion-recinto {
                .informacion-recinto--content {
                    .info-recinto {
                        align-items: center;
                        gap: 30px;
                        .info {
                            flex-basis: calc(100% - 128px - 30px);
                            .titulo-recinto {
                                p {
                                }
                            }
                            .direccion-recinto {
                                p {
                                    img {
                                    }
                                }
                            }
                            .btn--content {
                                .btn {
                                    .icon {
                                    }
                                    p {
                                    }
                                }
                            }
                        }
                        .image-recinto {
                            flex-basis: 128px;
                        }
                        .apertura {
                            flex-basis: 100%;
                            .icon {
                            }
                            p {
                                b {
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

section.wat-section.seccion-login {
    .seccion-login--content {
        padding: 0;
        width: 376px;
        margin: 0 auto;
        .logo {
        }
        .buttons {
        }
    }
}

section.wat-section.seccion-form {
    .seccion-form--content {
        padding: 0;
        width: 376px;
        margin: 0 auto;
        .logo {
        }
    }
}

section.wat-section.seccion-checkout-menu {
    top: 40px;
    position: fixed;
    .seccion-checkout-menu--content {
        position: relative;
        .btn--content {

        }
        .countdown {
            order: 3;
        }
        ul.menu {
            flex-basis: initial;
            position: absolute;
            z-index: 10;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

section.wat-section.seccion-checkout {
    .seccion-checkout--content {
        display: grid;
        grid-template-columns: auto 340px;
        align-items: flex-start;
        grid-gap: 20px;
        padding: 0 5vw;
        .info-fija {
            margin-bottom: 0;
            padding: 0;
            grid-area: 1 / 1 / 2 / 3; 
        }
        .checkout-pago {
            padding: 0;
            grid-area: 2 / 1 / 3 / 2; 
            .entrada-selected-cantidad {
                .entrada-selected-cantidad--content {
                    padding: 40px 16px;
                }
            }
        }
        .entradas-compradas {
            padding: 0;
        }
        &>form, &>div{
            &.full {
                grid-area: 2 / 1 / 3 / 3;
            }
        }

        .resumen {
            position: sticky;
            top: 60px;
            bottom: initial;
            left: initial;
            right: initial;
            border-radius: 10px;
            .resumen--content {
                .items {
                    height: initial !important;
                }
                .resumen-top {
                    .arrow {
                        display: none;
                    }
                }
            }
        }
    }
    .seccion-checkout--content--last{
        display: block;
    }
}

.countdown_queue{
    flex-direction: row;
    max-width: 80vw;
}
