/////////////////////////////////////////////////
/////////////////////////////////////////////////
//////////////   TIPOGRAFIAS   //////////////////
/////////////////////////////////////////////////
/////////////////////////////////////////////////


@font-face{
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    src: url('../../assets/fonts/Roboto-Regular.woff2') format('woff2');
    font-display: swap;
}

@font-face{
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 700;
    src: url('../../assets/fonts/Roboto-Bold.woff2') format('woff2');
    font-display: swap;
}

@font-face{
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 600;
    src: url('../../assets/fonts/Roboto-Medium.woff2') format('woff2');
}

@font-face {
    font-family: 'Trap';
    src: url('../../assets/fonts/Trap-Black.woff2') format('woff2');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Trap';
    src: url('../../assets/fonts/Trap-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Trap';
    src: url('../../assets/fonts/Trap-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Trap';
    src: url('../../assets/fonts/Trap-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Trap';
    src: url('../../assets/fonts/Trap-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Trap';
    src: url('../../assets/fonts/Trap-SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Trap';
    src: url('../../assets/fonts/Trap-ExtraBold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

h1, h2, h3, h4, h5, p, input, label, span, a, textarea {
    color: $white;
    font-family: "Roboto", sans-serif;
    font-weight: initial;
    margin: 0;
    padding: 0;
}


h1 {
    font-size: 32px;
    line-height: 40px;
    font-weight: 600;
    font-family: 'Trap';
    letter-spacing: -1px;
    @media screen and (min-width: 1400px) {
        font-size: 88px;
        line-height: 96px;
        letter-spacing: -3px;
    }
}
h1.event {
    font-size: 32px;
    line-height: 40px;
    font-weight: 600;
    font-family: "Roboto", sans-serif;
    letter-spacing: -1px;
    @media screen and (min-width: 1400px) {
        font-size: 88px;
        line-height: 96px;
        letter-spacing: -3px;
    }
}

h2 {
    font-size: 26px;
    line-height: 34px;
    font-weight: 400;
    font-family: 'Trap';
    @media screen and (min-width: 1400px) {
        font-size: 38px;
        line-height: 46px;
    }
}

h3 {
    font-size: 18px;
    line-height: 26px;
    font-weight: 700;
    text-transform: uppercase;
    position: relative;
    padding-left: 10px;
    &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 80%;
        width: 3px;
        border-radius: 3px;
        background-color: $primary;
    }
    @media screen and (min-width: 1400px) {
        font-size: 21px;
        line-height: 28px;
        letter-spacing: 4.2px;
    }
}

h4 {
    font-size: 26px;
    line-height: 34px;
    font-weight: 400;
    @media screen and (min-width: 1400px) {
        font-size: 38px;
        line-height: 46px;
    }
}

h5 {
    font-family: 'Trap';
    font-size: 22px;
    line-height: 30px;
    font-weight: 400;
    @media screen and (min-width: 1400px) {
        font-size: 35px;
        line-height: 42px;
    }
}