body,html {
    margin: 0 !important;
    padding: 0 !important;
    background-color: $background;
    font-family: "Roboto", sans-serif;
    color: $black;
    font-weight: 400;
}

img, svg {
    width: auto;
    max-width: 100%;
    height: auto;
    display: block;
}

.main-wrapper {
    width: 100%;
    height: 100%;
    @media screen and (min-width: 1400px) {
        max-width: 1240px;
        margin: 0 auto;
    }
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

a {
    cursor: pointer;
}

.loader-general {
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #182530b2;
    opacity: 0;
    visibility: hidden;
    transition: .3s ease;
    &.active {
        opacity: 1;
        visibility: visible;
    }
}

input, button, textarea {
    -webkit-appearance: none;
    box-shadow: none !important;
    &:focus {
        outline: none !important;
    }
}

label, button {
    cursor: pointer;
}


// ********************* Selección de texto ********************
p::selection, h1::selection, h2::selection, h3::selection, h4::selection, h5::selection, h6::selection,
a::selection, label::selection, span::selection, strong::selection, address::selection, mark::selection,
em::selection, time::selection, div::selection, i::selection, b::selection, u::selection, s::selection,
strike::selection, del::selection, ins::selection, q::selection, blockquote::selection, pre::selection,
code::selection, kbd::selection, samp::selection, var::selection, dfn::selection, abbr::selection,
acronym::selection, cite::selection, sub::selection, sup::selection, tt::selection, big::selection,
small::selection, ruby::selection, rt::selection, rp::selection, bdo::selection, wbr::selection,
ol li::selection, ul li::selection {
    background: $bg-input;
    color: $white;
    text-shadow: 0px 0px $white;
}

p::-moz-selection, h1::-moz-selection, h2::-moz-selection, h3::-moz-selection, h4::-moz-selection, h5::-moz-selection, h6::-moz-selection,
a::-moz-selection, label::-moz-selection, span::-moz-selection, strong::-moz-selection, address::-moz-selection, mark::-moz-selection,
em::-moz-selection, time::-moz-selection, div::-moz-selection, i::-moz-selection, b::-moz-selection, u::-moz-selection, s::-moz-selection,
strike::-moz-selection, del::-moz-selection, ins::-moz-selection, q::-moz-selection, blockquote::-moz-selection, pre::-moz-selection,
code::-moz-selection, kbd::-moz-selection, samp::-moz-selection, var::-moz-selection, dfn::-moz-selection, abbr::-moz-selection,
acronym::-moz-selection, cite::-moz-selection, sub::-moz-selection, sup::-moz-selection, tt::-moz-selection, big::-moz-selection,
small::-moz-selection, ruby::-moz-selection, rt::-moz-selection, rp::-moz-selection, bdo::-moz-selection, wbr::-moz-selection,
ol li::-moz-selection, ul li::-moz-selection {
    background: $bg-input;
    color: $white;
    text-shadow: 0px 0px $white;
}


// ********************* Barra Lateral ********************

::-webkit-scrollbar-track {
    border-radius: 0;
    background-color:  $black;
}

::-webkit-scrollbar {
    width: 6px;
    background-color: $bg-red;
}
::-webkit-scrollbar:horizontal{
    height: 0;
}
::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px $bg-red;
    background-color: $bg-red;
}


// TOASTR
#toast-container > div {
    font-family: "Roboto", sans-serif;
    border-radius: 2px 0px 0px 2px !important;
    padding: 10px 10px 10px 50px !important;
    font-size: 16px !important;
    line-height: 24px !important;
    background-size: 18px !important;
    box-shadow: none !important;
}
.toast-title {
    margin-bottom: 5px !important;
}
  
.toast-error {
    background-color: #E54D42 !important;
    border-right: 5px solid #B82E24;
}
  
.toast-warning {
    background-color: #FFC107 !important;
    border-right: 5px solid #FFA000;
}
  
.toast-success {
    background-color: #4CAF50 !important;
    border-right: 5px solid #388E3C;
}
  
.toast-info {
    background-color: #2196F3 !important;
    border-right: 5px solid #1976D2;
}

.avatar_text{
    width: 140px;
    height: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $primary;
    border-radius: 100%;
    font-size: 40px;
    text-transform: uppercase;
}
.icon-account{
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    background: $primary;
    border-radius: 100%;
    font-size: 10px;
    text-transform: uppercase;
}
.capitalize{
    text-transform: capitalize;
}

div:where(.swal2-container) .swal2-html-container{
    p{
        color: #000;
        text-align: left;
        font-size: 12px;
    }
}

.evento_pasado{
    opacity: 0.5;
    pointer-events: none;
}
.aviso_evento_pasado{
    span{
        background: #f77367;
        padding: 10px 20px;
        border-radius: 10px;
        display: flex;
        width: max-content;
        justify-content: center;
        .icon{
            width: 25px;
            margin-right: 10px;
        
        }
    }
    
}
.entradas-bloque{
    display: grid;
    gap: inherit;
}
.aviso_proceso{
    display: block;
    font-size: 14px;
    line-height: 21px;
    color: $grey;
    position: relative;
    border: 1px solid $grey;
    padding: 5px;
    margin-top: 5px;
    border-radius: 5px;
}

//CB
#fc_frame {
    opacity: 1;
    visibility: visible;
    transition: .3s ease;
    &.desactive {
        visibility: hidden;
        opacity: 0;
    }
}

#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyButtonAccept, #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonAccept, #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
    background-color: #f77367 !important;
    border-color: #f77367 !important;
    color: #fff !important;
}
#CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogBodyEdgeMoreDetails a{
    color: #f77367 !important;
}
#CybotCookiebotDialog a:hover:after, #CybotCookiebotDialog a:hover:before, #CybotCookiebotDialog button:hover:after, #CybotCookiebotDialog button:hover:before,
#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonCustomize, #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection,
#CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton{
    border-color: #f77367 !important;
    color: #000 !important;
    background-color: transparent !important;
}
#CookiebotWidget #CookiebotWidget-buttons #CookiebotWidget-btn-change{
    background-color: #f77367 !important;
    border-color: #f77367 !important;
}
#CookiebotWidget #CookiebotWidget-buttons #CookiebotWidget-btn-withdraw{
    border-color: #f77367 !important;
}
#CookiebotWidget .CookiebotWidget-body .CookiebotWidget-consents-list li.CookiebotWidget-approved svg{
    fill: #f77367 !important;
}
#CookiebotWidget .CookiebotWidget-consent-details button{
    color: #f77367 !important;
}
#CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink.CybotCookiebotDialogActive{
    border-color: #f77367 !important;
    color: #f77367 !important;
}
#CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink:hover{
    color: #f77367 !important;
}
#CybotCookiebotDialog input:checked+.CybotCookiebotDialogBodyLevelButtonSlider{
    background-color: #f77367 !important;
}
#CybotCookiebotDialogPoweredbyCybot svg{
    display: none !important;
}

#CybotCookiebotDialogPoweredbyImage{
    visibility: hidden;
    display: block !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    position: relative;
    overflow: visible;
}
#CybotCookiebotDialogPoweredbyImage::after {
    content: '';
    display: block;
    position: absolute;
    visibility: visible;
    left: 0;
    width: 145px;
    height: 40px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(https://klapper.pro/img/log_blue.svg);
    top: -5px;
}