/* You can add global styles to this file, and also import other style files */

@import 'ngx-toastr/toastr';

// @import 'swiper/scss';
// @import 'swiper/scss/navigation';
// @import 'swiper/scss/pagination';

@import '~@angular/material/prebuilt-themes/indigo-pink.css';

// Colours
@import "./scss/common/colours.scss";
// Common
@import "./scss/common/common.scss";
// Fuentes
@import "./scss/common/fonts.scss";
// Buttons
@import "./scss/common/buttons.scss";
// Mixins
@import "./scss/common/mixins.scss";
// Forms
@import "./scss/common/forms.scss";
// Templates
@import "./scss/common/templates.scss";

// *********************** Imagenes *********************** //

*{ 
    image-rendering: optimizeSpeed;             /*                     */
    image-rendering: -moz-crisp-edges;          /* Firefox             */
    image-rendering: -o-crisp-edges;            /* Opera               */
    image-rendering: -webkit-optimize-contrast; /* Chrome (and Safari) */
    image-rendering: optimize-contrast;         /* CSS3 Proposed       */
    -ms-interpolation-mode: nearest-neighbor;   /* IE8+                */
}

// ********************* Media queries ********************

@import "./scss/320.scss";

@media screen and (min-width: 480px) {
	@import "./scss/480.scss";
}

@media screen and (min-width: 768px) {
	@import "./scss/768.scss";
}

@media screen and (min-width: 1024px) {
	@import "./scss/1024.scss";
}

@media screen and (min-width: 1200px) {
    @import "./scss/1200.scss";
}

@media screen and (min-width: 1400px) {
    @import "./scss/1400.scss";
}

@media screen and (min-width: 1600px) {
    @import "./scss/1600.scss";
}

@media screen and (min-width: 1920px) {
    @import "./scss/1920.scss";
}