.wat-mainrow {
    overflow: hidden;
    position: relative;
    min-height: calc(100svh - 198px - 160px);
    padding: 80px 0; 
    &.page-login {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
}

.alert_dev{
    background: $bg-red;
    color: #fff;
    text-align: center;
    font-size: 10px;
    padding: 2px;
    position: fixed;
    width: 100%;
    z-index: 9999;
}

.wat-mainrow-admin, .wat-mainrow-public {
    min-height: calc(100svh - 198px - 200px);
    padding: 120px 0 80px;

    &.wat-mainrow-single-evento {
        display: flex;
        flex-direction: column;
        gap: 80px;
    }
    &.wat-mainrow-checkout {
        padding-top: calc(60px + 84px + 60px);
    }
}

section.wat-section {
    position: relative;
}

section.wat-section.seccion-fondo {
    overflow: hidden;
    position: absolute;
    top: 10svh;
    right: 0;
    left: 0;
    .seccion-fondo--content {
        transform: translateX(20vw);
    }
}

section.wat-section.seccion-fondo-evento {
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 400px;
    background-color: $background;
    overflow: hidden;
    .seccion-fondo-evento--content {
        width: 100%;
        height: 100%;
        &::after {
            content: "";
            position: absolute;
            bottom: 0px;
            left: 0px;
            right: 0px;
            height: 450px;
            background: linear-gradient(to top, $background 0%, rgba(8, 42, 69, 0.737) 19%, rgba(8, 42, 69, 0.54) 34%, rgba(8, 42, 69, 0.38) 47%, rgba(8, 42, 69, 0.28) 56.5%, rgba(8, 42, 69, 0.192) 65%, rgba(8, 42, 69, 0.125) 73%, rgba(8, 42, 69, 0.075) 80.2%, rgba(8, 42, 69, 0.043) 86.1%, rgba(8, 42, 69, 0.02) 91%, rgba(8, 42, 69, 0.008) 95.2%, rgba(8, 42, 69, 0.004) 98.2%, transparent 100%);
            // z-index: 1;
        }
        .fondo {
            position: absolute;
            background-size: cover;
            background-position: center center;
            top: -100px;
            left: -100px;
            right: -100px;
            height: 550px;
            opacity: 0.4;
            filter: blur(50px);
            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                object-fit: cover;
                width: 100%;
                height: 100%;
                display: block;
            }
        }
    }
}

header.header-admin {
    position: fixed;
    z-index: 900;
    top: 0;
    left: 0;
    right: 0;
    .header-admin--content {
        padding: 0 5vw;
        height: 70px;
        background-color: $background-bold;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .logo {
            width: 140px;
        }
        .bars {
            cursor: pointer;
            width: 40px;
            border-radius: 4px;
            aspect-ratio: 1;
            background-color: $white;
            position: relative;
            &.active {
                span {
                    &:nth-of-type(1) {
                        top: 19px;
                        transform: rotate(45deg);
                    }
                    &:nth-of-type(2) {
                        width: 0px;
                    }
                    &:nth-of-type(3) {
                        top: 19px;
                        width: 24px;;
                        transform: rotate(-45deg);
                    }
                }
            }
            span {
                position: absolute;
                right: 8px;
                width: 24px;
                height: 2px;
                border-radius: 2px;
                background-color: $black;
                transition: .3s ease;
                &:nth-of-type(1) {
                    top: 11px;
                }
                &:nth-of-type(2) {
                    right: initial;
                    left: 50%;
                    transform: translateX(-50%);
                    top: 19px;
                }
                &:nth-of-type(3) {
                    top: 27px;
                    width: 12px;
                }
            }
        }
        .header-secondary {
            position: absolute;
            top: 70px;
            left: 0;
            right: 0;
            height: calc(100svh - 70px);
            background-color: $background-bold;
            opacity: 0;
            visibility: hidden;
            transition: .3s ease;
            &.active {
                opacity: 1;
                visibility: visible;
            }
            .header-secondary--content {
                padding: 30px 5vw;
                height: calc(100% - 60px);
                ul {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    gap: 12px;
                    li {
                        &.active {
                            a {
                                color: $primary;
                            }
                        }
                        a {
                            transition: .3s ease;
                            font-size: 20px;
                            line-height: 28px;
                            font-weight: 400;
                            text-decoration: none;
                        }
                    }
                }                
            }
        }
    }
}

header.header {
    position: absolute;
    z-index: 900;
    top: 0;
    left: 0;
    right: 0;
    .header--content {
        padding: 0 5vw;
        height: 70px;
        // background-color: $background-bold;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .logo {
            width: 140px;
        }
        .icons {
            display: flex;
            gap: 10px;
            .icon {
                height: 26px;
                &.icon_search{
                    svg{
                       path{
                        stroke: #fff;
                       }
                    }
                }
                &.icon_user{
                    svg{
                        path{
                            fill: #fff;
                        }
                    }
                }
                img, svg {
                    width: auto;
                    height: 100%;
                }
            }
        }
    }
}
.load_more{
    text-align: center;
    margin-top: 50px;
    span{
        background-color: red;
        cursor: pointer;
        background-color: #182530;
        padding: 10px 20px;
        border-radius: 10px;
        text-transform: uppercase;
        text-decoration: none;
        font-size: 12px;
        border-radius: 8px;
        position: relative;
        border: 1px solid #182530;
        transition: 0.3s ease;
        &:hover{
            background-color: rgb(51, 51, 51);
        }
    }
}
.aviso_checkout{
    background-color: $primary;
    position: fixed;
    bottom: 10px;
    width: 360px;
    border-radius: 20px;
    z-index: 999;
    padding: 15px 20px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    left: 50%;
    translate: -50%;
    font-size: 14px;
    a{
        background-color: $background;
        padding:10px 20px;
        border-radius: 10px;
        text-transform: uppercase;
        text-decoration: none;
        font-size: 12px;
        border-radius: 8px;
        position: relative;
        border: 1px solid #182530;
        transition: 0.3s ease;
        &:hover{
            background-color: rgb(51, 51, 51);
        }
    }
}
footer.footer {
    position: relative;
    background-color: $background-bold;
    
    &.page-checkout {
        margin-bottom: 138px;
    }
    .footer--content {
        padding: 30px 5vw;
        display: flex;
        flex-direction: column;
        gap: 30px;
        ul {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 6px;
            li {
                a {
                    text-decoration: none;
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }
        .copyright {
            margin: 0 auto;
            max-width: 180px;
            p {
                text-align: center;
                font-size: 12px;
                line-height: 18px;
                a {
                    vertical-align: baseline;
                    margin-bottom: -2px;
                    display: inline-block;
                    align-items: center;
                    width: 56px;
                }
            }
        }
    }
}

.loader-filtros {
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #182530b2;
    opacity: 0;
    visibility: hidden;
    transition: .3s ease;
    &.active {
        opacity: 1;
        visibility: visible;
    }
    mat-progress-bar {
        width: 90vw;
        max-width: 240px;
        .mdc-linear-progress__buffer-bar {
            background-color: $primary;
        }
        .mdc-linear-progress__bar-inner {
            border-color: $bg-red;
        }
    }
}

section.wat-section.seccion-login {
    .seccion-login--content {
        padding: 0 5vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        .btn-register{
                margin-bottom: 20px;
                color: $primary;
                position: relative;
                font-size: 14px;
                line-height: 24px;
                text-decoration: none;
                &::after {
                    content: "";
                    position: absolute;
                    left: 0;
                    right: 0;
                    height: 1px;
                    background-color: $primary;
                    bottom: 2px;
                }
        }
        .logo {
            width: 270px;
            margin-bottom: 60px;
            // width: ;
        }
        .buttons {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 12px;
        }
    }
}

section.wat-section.seccion-form {
    .seccion-form--content {
        padding: 0 5vw;
        .logo {
            display: flex;
            width: 270px;
            margin: 0 auto 60px;
        }
    }
}

section.wat-section.seccion-mi-cuenta {
    .seccion-mi-cuenta--content {
        padding: 0 5vw;
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        .informacion {
            flex-basis: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 24px;
            padding-bottom: 30px;
            border-bottom: 1px solid $white;
            .avatar {
                width: 140px;
            }
            .informacion-texto {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 4px;
                p.name {
                    text-align: center;
                    font-size: 18px;
                    line-height: 24px;
                    color: $grey;
                }
                p.email {
                    text-align: center;
                    font-size: 12px;
                    line-height: 16px;
                    color: $grey;
                }
            }
            .buttons {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 12px;
            }
        }
        .form {
            width: 100%;
            .form--title {
                margin-bottom: 10px;
                p {
                    font-size: 26px;
                    line-height: 34px;
                    font-weight: 600;
                }
            }
        }
    }
}

section.wat-section.seccion-grid-eventos {
    .seccion-grid-eventos--content {
        display: flex;
        flex-direction: column;
        gap: 40px;
        padding: 0 5vw;
        .sin-eventos {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;
            h3 {
                text-align: center;
            }
            &>p {
                text-align: center;
                opacity: .7;
            }
        }
        .grid-eventos {
            .grid-eventos-title {
                margin-bottom: 12px;
                h5 {
                    span {
                        color: $primary;
                    }
                }
            }
            .grid-eventos-eventos, .grid-eventos-eventos-publicos {
                display: flex;
                flex-wrap: wrap;
                gap: 24px;
                .evento, .evento-publico {
                    flex-basis: 100%;
                    text-decoration: none;
                    cursor: pointer;
                }
            }
        }
        .grid-eventos--filtros {
            .grid-eventos--filtros--content {
                display: flex;
                flex-wrap: wrap;
                gap: 8px;
                .btn-filtro-parent {
                    background-color: $background;
                    border-radius: 8px;
                    position: relative;
                    border: 1px solid $white;
                    transition: .3s ease;
                    &:hover{
                        background-color: $background-bold;
                    }
                    &.active {
                        background-color: $background-bold;
                        border-color: $primary;
                    }
                    .cross {
                        cursor: pointer;
                        background-color: $bg-input;
                        position: absolute;
                        z-index: 10;
                        top: 16px;
                        right: 6px;
                        width: 16px;
                        height: 16px;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                       
                        svg {
                            width: 8px;
                        }
                    }
                    .btn-filtro--content {
                        padding: 12px 30px 12px 10px;
                        display: flex;
                        align-items: center;
                        gap: 4px;
                        
                        .icon {
                            width: auto;
                            max-width: 24px;
                            height: 24px;
                            flex-basis: 24px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-right: 5px;
                        }
                        p {
                            white-space: nowrap;
                            text-transform: uppercase;
                            font-size: 12px;
                            line-height: 16px;
                        }
                    }

                    .range--content {
                        padding: 12px 30px 12px 38px;
                        height: calc(100% - 24px);
                        display: flex;
                        align-items: center;
                        gap: 4px;
                        &>p {
                            order: 2;
                            white-space: nowrap;
                            text-transform: uppercase;
                            font-size: 12px;
                            line-height: 16px;
                        }
                        mat-date-range-input {
                            // display: none;
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            width: initial !important;
                            font-size: 0 !important;
                        }
                        mat-datepicker-toggle {
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            // order: 1;
                            // width: 24px;
                            // height: 24px;
                            button {
                                padding: 0 !important;
                                width: 100% !important;
                                height: 100% !important;
                                border-radius: 0 !important;
                                .mat-mdc-button-persistent-ripple {
                                    border-radius: 0 !important;
                                }
                                svg {
                                    position: absolute;
                                    top: 50%;
                                    transform: translateY(-50%);
                                    left: 10px;
                                    width: 24px;
                                    path {
                                        fill: $white;
                                    }
                                }
                            }
                        }
                    }
                    .location--content {
                        mat-select {
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            .mat-mdc-select-value {
                                display: none;
                            }
        
                            span {
                                font-size: 14px;
                                line-height: 22px;
                                color: $black;
                            }
                            .mat-mdc-select-trigger {
                                height: 100%;
                                padding: 0 12px;
                            }
                            .mat-mdc-select-arrow-wrapper {
                                display: none;
                                width: 30px;
                                height: 22px;
                                .mat-mdc-select-arrow {
                                    width: 24px;
                                    svg {
                                        fill: $background;
                                    }
                                }
                            }
                        }
                    }
                    .prices--content {
                        position: fixed;
                        z-index: 1300;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        opacity: 0;
                        visibility: hidden;
                        transition: .3s ease;
                        &.active {
                            opacity: 1;
                            visibility: visible;
                        }
                        .prices-events--transparencia {
                            position: absolute;
                            z-index: 1300;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            background-color: #182530b2;
                        }
                        .prices-events--content {
                            padding: 20px;
                            background-color: $white;
                            border-radius: 10px;
                            position: fixed;
                            z-index: 1330;
                            left: 50% !important;
                            transform: translateX(-50%);
                            width: 80vw;
                            max-width: 380px;
                            top: 50%;
                            mat-slider {
                                width: calc(100% - 16px);
                                --mdc-slider-handle-color: $black;
                                --mdc-slider-focus-handle-color: $black;
                                --mdc-slider-hover-handle-color: $black;
                                --mdc-slider-active-track-color: $black;
                                // --mdc-slider-inactive-track-color: $black;
                                --mdc-slider-with-tick-marks-active-container-color: $grey;
                                --mdc-slider-with-tick-marks-inactive-container-color: $black;
                                --mat-mdc-slider-ripple-color: $black;
                                .mdc-slider__value-indicator-text {
                                    white-space: nowrap;
                                    font-size: 12px;
                                    line-height: 16px;
                                    color: $white;
                                }
                            }
                        }
                    }
                }
            }
            
            .types-filter {
                margin-top: 20px;
                display: flex;
                gap: 8px 6px;
                flex-wrap: wrap;
                .btn-filtro-type {
                    position: relative;
                    
                    label {
                        display: flex;
                        align-items: center;
                        gap: 6px;
                        padding: 5px 10px;
                        background-color: $background;
                        border-radius: 8px;
                        border: 1px solid $white;
                        transition: .3s ease;
                        white-space: nowrap;
                        text-transform: uppercase;
                        font-size: 10px;
                        line-height: 16px;
                        &:hover{
                            background-color: $background-bold;
                        }
                    }
                    input {
                        opacity: 0;
                        position: absolute;
                        &:checked + label {
                            background-color: $background-bold;
                            border-color: $primary;
                            .cross {
                                display: flex;
                            }
                        }
                    }
                    .cross {
                        background-color: $bg-input;
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                        display: none;
                        align-items: center;
                        justify-content: center;
                        svg {
                            width: 6px;
                        }
                    }
                }
            }

        }
    }
}

section.wat-section.seccion-publicar-evento {
    margin-top: 40px;
    .seccion-publicar-evento--content {
        padding: 0 5vw;
        .contactar {
            background-color: $background-bold;
            background-image: url(../assets/images-defaul/salmon_toned_image.png);
            background-size: cover;
            background-position: center center;
            padding: 30px;
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;
            min-height: 200px;
            .titulo {
                p {
                    font-weight: 600;
                   
                    font-size: 35px;
                    line-height: 45px;
                    color: $background;
                   // letter-spacing: -1.733px;
                }
            }
        }
    }
}

section.wat-section.seccion-evento-ind {
    .seccion-evento-ind--content {
        padding: 0 5vw;
        display: flex;
        flex-direction: column;
        gap: 30px;
        .image-evento {
            border-radius: 10px;
            position: relative;
            overflow: hidden;
            aspect-ratio: 1/1;
            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                object-fit: cover;
                width: 100%;
                height: 100%;
                display: block;
            }
        }
        .informacion-evento {
            display: flex;
            flex-direction: column;
            gap: 32px;
            .cabecera {
                display: flex;
                flex-direction: column;
                gap: 10px;
                .informacion {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 8px;
                    .info-icon {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        &.info-icon-destacada {
                            p {
                                font-weight: 700;
                                color: $primary;
                            }
                        }
                        .icon {
                            flex-basis: 24px;
                            min-width: 24px;
                        }
                        p {
                            color: $grey;
                            font-size: 18px;
                            line-height: 24px;
                           // text-transform: uppercase;
                        }
                        .horas {
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            gap: 8px;
                            .icon-separate {
                                flex-basis: 16px;
                                min-width: 16px;
                            }
                        }
                    }
                }
                .informacion-secundaria {
                    display: flex;
                    align-items: flex-start;
                    flex-wrap: wrap;
                    gap: 8px 16px;
                    .info-icon {
                        display: flex;
                        align-items: center;
                        gap: 4px;
                        &.info-icon-destacada {
                            p {
                                font-weight: 700;
                                color: $primary;
                            }
                        }
                        .icon {
                            flex-basis: 24px;
                            min-width: 24px;
                        }
                        p {
                            white-space: nowrap2;
                            font-size: 18px;
                            line-height: 24px;
                        }
                    }
                }
            }
            .entradas-packs {
                .entradas-packs--content {
                    .title {
                        margin-bottom: 10px;
                    }
                    .items {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 20px;
                        .entrada, .pack {
                            flex-basis: 100%;
                        }
                    }
                }
            }
            .informacion-general {
                padding-bottom: 30px;
                border-bottom: 1px solid #ffffff34;
                .informacion-general--content {
                    .title {
                        margin-bottom: 10px;
                    }
                    .informacion {
                        margin-bottom: 20px;
                        display: flex;
                        flex-direction: column;
                        gap: 8px;
                        font-size: 14px;
                            line-height: 20px;
                            color: $grey;
                        p {
                            font-size: 14px;
                            line-height: 20px;
                            color: $grey;
                        }
                    }
                    .informacion-secundaria {
                        display: flex;
                        flex-direction: column;
                        gap: 16px;
                        .info-icon {
                            display: flex;
                            align-items: flex-start;
                            flex-wrap: wrap;
                            gap: 12px;
                            &.info-icon-destacada {
                                p {
                                    font-weight: 700;
                                    color: $primary;
                                }
                            }
                            .icon {
                                flex-basis: 24px;
                                min-width: 24px;
                            }
                            .inf {
                                flex-basis: calc(100% - 24px - 12px);
                                p {
                                    font-size: 14px;
                                    line-height: 20px;
                                    color: $grey;
                                    b {
                                        color: $white;
                                    }
                                }
                            }
                            ul {
                                padding: 0 30px;
                                li {
                                    list-style: disc;
                                    list-style-position: inside;
                                    font-size: 14px;
                                    line-height: 20px;
                                    color: $grey;
                                }
                            }
                        }
                    }
                }
            }
            .informacion-recinto {
                .informacion-recinto--content {
                    .title {
                        margin-bottom: 10px;
                    }
                    .info-recinto {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 30px 40px;
                        .info {
                            flex-basis: 100%;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 12px;
                            .titulo-recinto {
                                p {
                                    font-size: 24px;
                                    line-height: 32px;
                                    font-weight: 400;
                                }
                            }
                            .direccion-recinto {
                                p {
                                    svg {
                                        cursor: pointer;
                                        display: inline-block;
                                        width: 24px;
                                    }
                                }
                            }
                            .btn--content {
                                .btn {
                                    display: flex;
                                    align-items: center;
                                    gap: 8px;
                                    text-decoration: none;
                                    padding: 8px 18px 8px 10px;
                                    background-color: $primary;;
                                    border-radius: 20px;
                                    .icon {
                                        flex-basis: 24px;
                                        min-width: 24px;
                                    }
                                    p {
                                        font-weight: 700;
                                        font-size: 12px;
                                        line-height: 16px;
                                        text-transform: uppercase;
                                    }
                                }
                            }
                        }
                        .image-recinto {
                            flex-basis: 100%;
                            border-radius: 10px;
                            overflow: hidden;
                        }
                        .apertura {
                            display: flex;
                            align-items: flex-start;
                            gap: 12px;
                            .icon {
                                margin-top: -4px;
                                flex-basis: 24px;
                                min-width: 24px;
                            }
                            p {
                                color: $grey;
                                b {
                                    color: $white;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

section.wat-section.seccion-checkout-menu {
    position: absolute;
    z-index: 1000;
    top: 60px;
    left: 0;
    right: 0;
    .seccion-checkout-menu--content {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        padding: 0 5vw;
        .btn--content {
            order: 1;
            display: flex;
            justify-content: flex-start;
            a.btn {
                text-decoration: none;
                background-color: $background;
                display: flex;
                align-items: center;
                gap: 16px;
                padding: 8px 18px;
                border-radius: 38px;
                .icon {
                    transform: rotate(180deg);
                    min-width: 16px;
                    flex-basis: 16px;
                }
                p {
                    text-transform: uppercase;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }
        .countdown {
            order: 2;
            p {
                font-size: 16px;
                line-height: 24px;
                color: $primary;
                span {
                    color: $white;
                }
            }
        }
        ul.menu {
            order: 3;
            flex-basis: 100%;
            display: flex;
            align-items: center ;
            justify-content: center;
            gap: 8px;
            li {
                min-width: 60px;
                a {
                    pointer-events: none;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: row;
                    span{
                        border: 1px solid #fff;
                        border-radius: 100%;
                        width: 20px;
                        height: 20px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 10px;
                        margin-right: 10px;
                    }
                    &.enable {
                        pointer-events: initial;
                    }
                    &.active {
                        p {
                            color: $primary;
                        }
                        span{
                            color: $primary;
                            border-color: $primary;
                        }
                    }
                }
            }
            .separator {
                width: 24px;
                height: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    width: 16px;
                }
            }
        }
    }
}

section.wat-section.seccion-checkout {
    .seccion-checkout--content {

        .info-fija {
            margin-bottom: 20px;
            padding: 0 5vw;
            display: flex;
            flex-direction: column;
            gap: 20px;
            
            .info-mini {
                display: flex;
                flex-direction: column;
                gap: 8px;
                .info-icon {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    &.info-icon-destacada {
                        p {
                            font-weight: 700;
                            color: $primary;
                        }
                    }
                    .icon {
                        flex-basis: 24px;
                        min-width: 24px;
                        
                    }
                    p {
                        color: $grey;
                        letter-spacing: 2.4px;
                        font-size: 18px;
                        line-height: 24px;
                        //text-transform: uppercase;
                    }
                    .horas {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        gap: 8px;
                        .icon-separate {
                            flex-basis: 16px;
                            min-width: 16px;
                        }
                    }
                }
            }
            .titulo {
                &.thanks{
                    padding-bottom: 10px;
                    border-bottom: 2px solid $primary;
                    p{
                        font-size: 30px;
                        line-height: 38px;
                        &.size-full{
                            font-size: 40px;
                            line-height: 48px;
                        }
                    }
                }
                p {
                    font-size: 20px;
                    line-height: 28px;
                    font-weight: 600;
                }
            }
            .codigo {
                background-color: $background-bold;
                .codigo--content {
                    form.formulario-codigo-evento {
                        .formulario-codigo-evento--content {
                            .input--content {
                                input {
                                    background-color: #183045;
                                }
                            }
                        }
                    }
                }
            }
        }

        .checkout-pago {
            padding: 0 5vw;
            flex-direction: column;
            gap: 20px;
            display: none;
            &.active {
                display: flex;
            }

            .entrada-selected {
                padding: 16px;
                background: rgba(247, 115, 103, 0.05);
                border: 1px solid $primary;
                border-radius: 10px;
                .entrada-selected--content {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    gap: 20px;
                    flex-basis: calc(100% - 80px);
                    .info-entrada {
                        display: flex;
                        flex-direction: column;
                        gap: 4px;
                        .entrada-name {
                            p {
                                font-weight: 600;
                                color: $grey;
                            }
                        }
                        .entrada-info {
                            p {
                                color: $grey;
                                font-size: 14px;
                                line-height: 20px;
                            }
                        }
                    }
                    .precio-entrada {
                        flex-basis: 60px;
                        p {
                            text-align: right;
                            font-weight: 600;
                            color: $primary;
                            font-size: 18px;
                            line-height: 26px;
                        }
                    }
                }
            }
            .entrada-selected-info {
                padding: 16px;
                background: rgba(247, 115, 103, 0.05);
                border: 1px solid $white;
                border-radius: 10px;
                .entrada-selected-info--content {
                    .desplegable {
                        display: flex;
                        align-items: flex-start;
                        gap: 8px;
                        .icon {
                            margin-top: -2px;
                            min-width: 24px;
                            flex-basis: 24px;
                        }
                        p {
                            font-size: 14px;
                            line-height: 20px;
                            a {
                                font-size: 14px;
                                line-height: 20px;
                                text-decoration: underline;
                                font-style: italic;
                                color: $grey;
                            }
                        }
                    }
                    .desplegado {
                        transition: .3s ease;
                        display: flex;
                        flex-direction: column;
                        gap: 4px;
                        p {
                            font-size: 14px;
                            line-height: 20px;
                            color: $grey;
                        }
                    }
                }
            }
            .entrada-selected-cantidad {
                .entrada-selected-cantidad--content {
                    padding: 16px;
                    background: $background-bold;
                    border: 1px solid $background-bold;
                    border-radius: 10px;
                    .seleccionar-cantidad-title {
                        margin-bottom: 20px;
                        p {
                            text-align: center;
                            font-size: 20px;
                            line-height: 26px;
                            color: $grey;
                        }
                    }
                    .cantidades {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 10px;
                        .less, .more {
                            cursor: pointer;
                            width: 48px;
                            height: 48px;
                            border-radius: 50%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background: rgba(63, 63, 70, 0.60);
                            box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
                            p {
                                color: $grey;
                                text-align: center;
                                font-size: 20px;
                                line-height: 26px;
                            }
                        }
                        input {
                            width: 60px;
                            text-align: center;
                            background-color: initial;
                            border: none;
                            font-size: 30px;
                            line-height: 36px;
                            color: $white;
                            &::-webkit-outer-spin-button,
                            &::-webkit-inner-spin-button {
                                -webkit-appearance: none;
                                margin: 0;
                            }
                            &[type=number] {
                                -moz-appearance: textfield;
                            }
                        }
                    }
                }
            }

            .asistentes {
                display: flex;
                flex-direction: column;
                gap: 20px;
                .asistente {
                    .asistente--content {
                        .asistente-titulo {
                            padding: 10px 20px;
                            border-radius: 10px 10px 0 0;
                            background-color: #203148;
                            p {
                                text-transform: uppercase;
                                color: $primary;
                                font-size: 14px;
                                line-height: 21px;
                                font-weight: 600;
                            }
                        }
                        .form--content {
                            padding: 20px;
                            background-color: $background-bold;
                            border-radius: 0 0 10px 10px;
                        }
                    }
                }
            }
            .spam {
                label {
                    font-size: 14px;
                    line-height: 21px;
                    color: $grey;
                    position: relative;
                    b {
                        font-size: 14px;
                        line-height: 21px;
                        color: $grey;
                        font-weight: 600;
                    }
                    &::before {
                        content: "";
                        display: inline-block;
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                        border: 1px solid $grey;
                        margin-right: 8px;
                        transform: translateY(2px);
                    }
                    &::after {
                        content: "";
                        position: absolute;
                        width: 6px;
                        height: 6px;
                        display: block;
                        border-radius: 50%;
                        background-color: $grey;
                        top: 4px;
                        left: 4px;
                        opacity: 0;
                        visibility: hidden;
                        transition: .3s ease;
                    }
                }
                input {
                    position: absolute;
                    &:checked + label {
                        &::after {
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
            }

            .opciones-pago {
                display: flex;
                flex-direction: column;
                gap: 20px;
                .opcion-pago {
                    cursor: pointer;
                    padding: 16px;
                    background: rgba(247, 115, 103, 0.05);
                    border: 1px solid $white;
                    border-radius: 10px;
                    transition: .3s ease;
                    &.active {
                        border-color: $primary;
                    }
                    &.disabled{
                        pointer-events: none;
                        opacity: 0.4;
                    }
                    .opcion-pago--content {
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-start;
                        gap: 20px;
                        flex: auto;
                        .info-pago {
                            display: flex;
                            flex-direction: column;
                            gap: 4px;
                            .pago-name {
                                p {
                                    font-weight: 600;
                                    color: $grey;
                                }
                            }
                            .pago-info {
                                p {
                                    color: $grey;
                                    font-size: 14px;
                                    line-height: 20px;
                                }
                            }
                        }
                        .precio-pago {
                            // flex-basis: 60px;
                            p {
                                white-space: nowrap;
                                text-align: right;
                                font-weight: 600;
                                color: $primary;
                                font-size: 18px;
                                line-height: 26px;
                                span {
                                    font-weight: 600;
                                    color: $primary;
                                    font-size: 18px;
                                    line-height: 26px;
                                    opacity: .6;
                                }
                            }
                        }
                    }
                }
            }
        }

        .entradas-compradas {
            padding: 0 5vw;
            display: flex;
            flex-wrap: wrap;
            gap: 20px 24px;
            .entrada-comprada {
                flex-basis: 100%;
            }
            &.disabled{
                opacity: 0.6;
                pointer-events: none;
            }
        }

        .resumen {
            position: fixed;
            z-index: 999;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: $black;
            box-shadow: 0 -3px 6px #00000029;
            border-radius: 30px 30px 0 0;
            &.calculando {
                .resumen--content {
                    filter: blur(4px);
                }
            }
            .resumen--content {
                transition: .3s ease;
                padding: 20px;
                .items {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    .item {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        gap: 40px;
                        &.item-descuento{
                            p{
                                color:$primary;
                            }
                        }
                        &.item-destacado {
                            p {
                                font-weight: 600;
                                color: $white;
                            }
                        }
                        p {
                            font-size: 16px;
                            line-height: 24px;
                            font-weight: 300;
                            color: $grey;
                            &:nth-last-of-type(1) {
                                text-align: right;
                            }
                        }
                    }
                }
                .resumen-top {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    .title {
                        font-size: 16px;
                        line-height: 24px;
                        font-weight: 600;
                    }
                    .arrow {
                        cursor: pointer;
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: 22px;
                        height: 22px;
                        border: 1px solid $white;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        transition: .3s ease;
                        &.active {
                            transform: rotate(180deg);
                        }
                        img, svg{
                            width: 10px;
                        }
                    }
                }
                .resumen-bottom {
                    margin-top: 10px;
                    padding-top: 10px;
                    border-top: 2px solid $bg-input;
                    button.btn-primary {
                        width: 100%;
                        margin-top: 10px;
                        padding: 12px 14px;
                        &[disabled] {
                            opacity: .5;
                        }
                    }
                }
                .resumen-terminos {
                    margin-top: 20px;
                    p {
                        font-size: 10px;
                        line-height: 16px;
                        color: $grey;
                        b {
                            font-weight: 600;
                        }
                        a {
                            font-size: 10px;
                            line-height: 16px;
                            color: $grey;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }

    }
    .seccion-checkout--content--last{
        .entradas-compradas{
            margin: 40px 0px;
        }
    }
}

.selector_envio{
    display: flex;
    border-radius: 10px;
    overflow: hidden;
    span{
        width:50%;
        text-align: center;
        padding: 10px;
        background-color: $primary;
        opacity: 0.5;
        cursor: pointer;
        &.active{
            pointer-events: none;
        }
        &.active, &:hover{
            opacity: 1;
        }
    }
}

.content-legal{
    margin: 50px 0px;
    
    h1{
        font-family: "Roboto", sans-serif;
        font-weight: bold;
        font-size: 60px;
        margin-bottom: 30px;
        line-height: normal;
    }
    h2{
        font-family: "Roboto", sans-serif;
        font-size: 30px;
        font-weight: bold;
        color: $primary;
    }
    p{
        margin: 20px 0px;
    }
    ul {
        padding: 0px 20px;
        list-style: disc;    
        margin: 20px 0px;
        li{
            color: #fff;
        }
    }
}

.inf_cond_gen{
    cursor: pointer;
    &:hover{
        text-decoration: underline;
    }
}

section.wat-section.seccion-popup-informacion {
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    transition: .3s ease;
    &.active {
        opacity: 1;
        visibility: visible;
    }
    .transparencia {
        background-color: #082A4599;
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    .seccion-popup-informacion--content {
        width: 90vw;
        max-width: 400px;
        border-radius: 10px;
        overflow: hidden;
        box-shadow: 0px -3px 6px -5px rgba(0, 0, 0, 0.15);
        .informacion {
            .informacion-top {
                display: flex;
                a.btn {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0 20px;
                    background: rgba(255, 255, 255, 0.70);
                    transition: .3s ease;
                    &.active {
                        background-color: $white;
                        p {
                            color: $black;
                        }
                    }
                    p {
                        font-size: 13px;
                        line-height: 15px;
                        font-weight: 600;
                        color: #777;
                        transition: .3s ease;
                    }
                }
                .close {
                    position: relative;
                    width: 46px;
                    min-width: 46px;
                    height: 46px;
                    background: rgba(255, 255, 255, 0.70);
                    span {
                        width: 14px;
                        height: 2px;
                        border-radius: 1px;
                        background-color: #777;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        &:nth-of-type(1) {
                            transform: translate(-50%, -50%) rotate(-45deg);
                        }
                        &:nth-of-type(2) {
                            transform: translate(-50%, -50%) rotate(45deg);
                        }
                    }
                }
            }
            .informacion-total {
                padding: 36px 22px;
                background-color: $white;
                .informacion-total--content {
                    max-height: calc(100svh - 160px);
                    overflow: auto;
                    padding: 0 4px;
                }
            }
        }
    }   
}
.seccion-legal{
    padding:20px;
}
.logo_cola{
    width: 200px;
    display: block;
    text-align: center;
    margin: 0 auto;
}
.cola-virtual{
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
    flex-direction: column;
    padding: 30px 5vw;
    gap: 20px;
    .logo_cola{
        width: 200px;
        margin-bottom: 30px; 
    }
    .cola-section {
        background-color: rgb(0 0 0 / 20%);
        border-radius: 10px;
        padding: 30px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 30px;
    }
    .dots {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .progress-container {
        width: 80%;
        max-width: 80%;
        height: 30px;
        background-color: $background;
        border-radius: 5px;
        position: relative;
        overflow: hidden;
        margin-top:20px;
        margin-bottom:20px;
    }
    .progress-bar {
        height: 100%;
        width: 75%; /* Set to a percentage representing the progress */
        background-color: $primary;
        position: relative;
        transition: width 0.4s ease;
    }
    .progress-icon {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        height: 20px;
        width: 20px;
    }
    .progress-icon svg {
       fill:#fff;
       animation: caminar 1s infinite;
        transform-origin: center;
    }
    
    @keyframes caminar {
        0% { transform: translateY(0) rotate(0deg); }
        25% { transform: translateY(-2px) rotate(-5deg); }
        50% { transform: translateY(0) rotate(0deg); }
        75% { transform: translateY(-2px) rotate(5deg); }
        100% { transform: translateY(0) rotate(0deg); }
    }


    /*
    .progress-icon svg {
       fill:#fff;
       animation: subirBajar .7s infinite ease-in-out;
    }
    
    @keyframes subirBajar {
        0% { transform: translateY(0); }
        50% { transform: translateY(-2px); }
        100% { transform: translateY(0); }
    }
    */
    
    .title-cola{
        font-size: 30px;
        span{
            color: $primary;
            font-weight: bold;
        }
    }
    .dot {
        width: 10px;
        height: 10px;
        background-color: #F8736A;
        border-radius: 50%;
        margin: 0 5px;
        animation: pulse 1.5s infinite;
    }

    .dot:nth-child(2) {
        animation-delay: 0.2s;
    }

    .dot:nth-child(3) {
        animation-delay: 0.4s;
    }

    @keyframes pulse {
        0%, 100% {
            opacity: 0.3;
        }
        50% {
            opacity: 1;
        }
    }

    .timer {
        font-size: 1.2rem;
        font-weight: bold;
        color: #F8736A;
    }

    .footer-text {
        font-size: 0.8rem;
        color: #999;
    }
    .info-section{
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding:10px;
        gap: 20px;
        b {
            display: block;
            font-size: 20px;
            line-height: 30px;
            color: #F8736A;
        }
    }
}

.wat-mainrow-public.wat-mainrow-public-queue{
    padding: 120px 0 80px;
}

.countdown_queue{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 10px;
    .countdown_dias, .countdown_horas, .countdown_minutos, .countdown_segundos{
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        min-width: 100px;
        flex-direction: column;
        border: 1px solid #F77367;
        padding: 5px;
        .number{
            font-size: 30px;
            color: $primary;
        }
        span{
            font-weight: bold;
        }
    }
}

.previous_price{
    opacity: 0.4;
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text{
    color:$primary !important;
}
.mat-calendar-body-in-range::before, .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical){
    background: rgba(247, 115, 103,0.5) !important;
}
.mat-calendar-body-selected{
    background-color:$primary !important;
}
.mat-calendar-body-active .mat-calendar-body-today{
    background: rgba(247, 115, 103,0.5) !important;
}