/////////////////////////////////////////////////
/////////////////////////////////////////////////
//////////////   COLORES   //////////////////////
/////////////////////////////////////////////////
/////////////////////////////////////////////////

$black: #2B2C2D;
$white: #FFFFFF;
$grey: #A1A1AA;

$background: #082A45;
$background-bold: #182530;

$bg-red: #D6494B;
$bg-black: #0A0A0A;
$bg-red-light: #F77367;

$bg-input: #3F3F46;

$primary: #F77367;